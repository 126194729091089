import resume from '../assets/pdf/resume.pdf'
import photocv from '../assets/svg/about/photocv.png'

export const headerData = {
    name: 'Hamza El hilali',
    title: "Web Developer Full stack",
    desciption:"Hello, I am full stack web / mobile developer, i'm applying the academic and professional knowledge acquired from Youcode school and 3 years in the field of web / mobile development.  ",
    image: photocv,
    resumePdf: resume
}
